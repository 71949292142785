import "./App.css";
import { ErrorBoundary } from "./components/error-boundary/ErrorBoundary";
import { RouterConfig } from "./navigation/RouterConfig";

function App() {
  return (
    <ErrorBoundary>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>

      <RouterConfig />
    </ErrorBoundary>
  );
}

export default App;
